<template>
  <v-container>
    <v-btn @click="createMarker" color="primary" block>Add marker</v-btn>
    <v-row class="text-center">
      <v-col v-for="marker in $store.state.offline.offMarkers" :key="marker.id" cols="6">
        <v-card>
          <v-card-title>
            {{ marker.id }}
          </v-card-title>
          <v-card-text>
            <v-img :src="marker.image" contain>

            </v-img>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="removeMarker(marker)" color="error">Remove</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-img :src="imageUrl" ></v-img> -->

    <v-dialog v-model="dialog">
      <v-card>
        <v-card-text>
          <v-form>
            <v-file-input v-model="image"></v-file-input>
            <v-btn @click="addMarker">Add</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      dialog: false,
      image: null,

      imageUrl: null
    }),
    methods: {
      createMarker () {
        this.dialog = true;
      },
      async addMarker () {
        const id = this.$store.getters.getLastId + 1;
        this.$store.dispatch('setMarker', { id, image: await this.getImageAsBase64() })
        this.$store.dispatch('getMarkers');

        this.dialog = false;
      },

      removeMarker (marker) {
        this.$store.dispatch('deleteMarker', marker);
        this.$store.dispatch('getMarkers');
      },

      async getImageAsBase64 () {
        // return await this.readFileAsync();
        return new Promise((resolve, reject) => {
          var reader = new FileReader();
        
          reader.onload = () => {
            resolve(reader.result)
          }

          reader.onerror = reject

          reader.readAsDataURL(this.image);
        })
      }
    }
  }
</script>
