import Vue from 'vue'
import Vuex from 'vuex'

import idb from '@/services/IDbService'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    offline: {
      offMarkers: []
    }
  },
  getters: {
    getLastId (state) {
      return (state.offline.offMarkers && state.offline.offMarkers.length > 0) ? state.offline.offMarkers.at(-1).id : 0
    }
  },
  mutations: {
  },
  actions: {
    async getMarkers(context) {
      context.state.offline.offMarkers = [];
      let markers = await idb.getMarkers();
      markers.forEach(c => {
        context.state.offline.offMarkers.push(c);
      });
    },

    async setMarker(context, marker) {
      await idb.saveMarker(marker);
    },

    async deleteMarker(context, marker) {
      await idb.deleteMarker(marker); 
    },
  },
  modules: {
  }
})
